<template>
  <div class="container">
    <div class="wrapper">
      <img src="../assets/sh_banner@2x.png" alt="" class="banner" />
      <div class="img"></div>
      <div class="step_wrapper">
        
        <img
          class="bz_img"
          v-if="curstep == 1"
          src="../assets/spbz_icon1@2x.png"
          alt=""
        />
        <img
          class="bz_img"
          v-if="curstep == 2"
          src="../assets/spbz_icon2@2x.png"
          alt=""
        />
      </div>
      <div v-if="curstep == 1" class="main" style="margin-top: 24px">
        <div class="title">
          <img src="../assets/djxx_icon_xqxx.png" alt="" class="icon" />
          店铺基本信息
        </div>
        <div class="content">
          <div class="form_item">
            <div class="item_flex">
              <div class="label">店铺LOGO*</div>
              <div class="fileRight">
                <van-uploader
                  v-if="formObj.logoList && formObj.logoList.length > 0"
                  :after-read="upload_logo"
                >
                  <!-- <img src="../assets/zc_icon02@2x.png" class="logo" alt="" /> -->
                  <img :src="formObj.logoList[0]" class="logo" alt="" />
                </van-uploader>
                <van-uploader v-else :after-read="upload_logo">
                  <img src="../assets/logo_img.png" class="logo" alt="" />
                </van-uploader>
              </div>
            </div>
          </div>
          <div class="form_item">
            <van-field v-model="formObj.title" label="门店名称*" />
          </div>
          <div class="form_item">
            <van-field v-model="formObj.address" label="店铺地址*" />
          </div>
          <div class="form_item">
            <van-field v-model="formObj.phone" label="联系电话*" />
          </div>
          <div class="form_item">
            <div class="item_box">
              <div class="name">是否上门服务*</div>
              <van-radio-group
                @change="changetype"
                v-model="formObj.type"
                direction="horizontal"
              >
                <van-radio checked-color="#ee0a24" :name="1">是</van-radio>
                <van-radio checked-color="#ee0a24" :name="2">否</van-radio>
              </van-radio-group>
            </div>
          </div>
          <div class="form_item">
            <van-cell-group>
              <van-cell
                @click="showcate_id = true"
                title="店铺类型*"
                :value="formObj.cate || '选择'"
                is-link
              />
            </van-cell-group>
          </div>
          <div class="form_item">
            <van-field v-model="formObj.name" label="您的姓名*" />
          </div>
          <div class="form_item">
            <van-field v-model="formObj.id_card" label="身份证号*" />
          </div>
          <div class="form_item">
            <van-field v-model="formObj.mobile1" label="联系电话1*" />
          </div>
          <div class="form_item">
            <van-field v-model="formObj.mobile2" label="联系电话2*" />
          </div>
          <div class="form_item">
            <div class="textarea_box">
              <div class="label_name">店铺展示照片*</div>
              <van-uploader
                :max-count="1"
                @delete="delete_image"
                :after-read="upload_image"
                v-model="fileList1"
              >
                <img
                  src="../assets/fabu_ico_upload@2x.png"
                  class="file_img"
                  alt=""
                />
              </van-uploader>
            </div>
          </div>
          <div class="form_item">
            <div class="textarea_box">
              <div class="label_name">营业执照*</div>
              <van-uploader
                @delete="delete_license"
                :after-read="upload_license"
                :max-count="1"
                v-model="fileList2"
              >
                <img
                  src="../assets/fabu_ico_upload@2x.png"
                  class="file_img"
                  alt=""
                />
              </van-uploader>
            </div>
          </div>
          <div class="form_item">
            <div class="textarea_box">
              <div class="label_name">店铺简介*</div>
              <van-field
                v-model="formObj.describe"
                rows="2"
                autosize
                type="textarea"
                maxlength="50"
                placeholder="店铺简介"
                show-word-limit
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="curstep == 2"
        class="main"
        style="margin-top: 24px; padding-bottom: 20px"
      >
        <div class="title">
          <img src="../assets/djxx_icon_xqxx.png" alt="" class="icon" />
          服务项目信息
        </div>
        <div
          v-for="(item, index) in productlist"
          :key="index"
          @click="handleProductItem(item, index)"
          class="item_list"
        >
          <img
            :src="item.product_imgList && item.product_imgList[0]"
            alt=""
            class="left"
          />
          <div class="info">
            <div class="name">{{ item.title }}</div>
            <div class="desc">{{ item.content }}</div>
          </div>
          <img
            @click.stop="delItem(item, index)"
            src="../assets/com_icon_del@2x.png"
            alt=""
            class="del"
          />
        </div>
        <div class="addBtn" @click="show = true">+添加项目</div>
        <van-popup
          v-model="show"
          closeable
          close-icon="close"
          position="bottom"
          :style="{ height: '80%' }"
        >
          <div class="content" style="padding-top: 36px">
            <div class="form_item">
              <van-field v-model="product.title" label="项目名称*" />
            </div>
            <div class="form_item">
              <div class="textarea_box">
                <div class="label_name">项目图片</div>
                <van-uploader
                  @delete="delete_product_img"
                  :max-count="1"
                  :after-read="upload_product_img"
                  v-model="product_imgList"
                >
                  <img
                    src="../assets/fabu_ico_upload@2x.png"
                    class="file_img"
                    alt=""
                  />
                </van-uploader>
              </div>
            </div>
            <div class="form_item">
              <div class="textarea_box">
                <div class="label_name">项目简介*</div>
                <van-field
                  v-model="product.content"
                  rows="2"
                  autosize
                  type="textarea"
                  maxlength="50"
                  placeholder="项目简介"
                  show-word-limit
                />
              </div>
            </div>
            <!-- <div class="form_item"> -->
            <div class="btn" style="margin-top: 20px" @click="handleSubmit">
              保存
            </div>
            <!-- </div> -->
          </div>
        </van-popup>
      </div>
      <div class="btn_wrapper">
        <!-- <div class="btn" @click="submit">确认提交</div> -->
        <div
          v-if="$route.query.isDetail != 1 && curstep != 2"
          class="btn"
          @click="nextstep"
        >
          下一步
        </div>
        <div
          v-if="$route.query.isDetail != 1 && curstep == 2"
          class="btn"
          @click="nextstep"
        >
          确认提交
        </div>
        <div
          v-if="$route.query.isDetail == 1 && curstep != 2"
          class="btn"
          @click="nextstep"
        >
          下一步
        </div>
        <div
          v-if="$route.query.isDetail == 1 && curstep == 2"
          class="btn"
          @click="nextstep"
        >
          确认提交
        </div>
      </div>
      <van-popup v-model="showcate_id" round position="bottom">
        <van-picker
          show-toolbar
          :columns="cate_idlistcolumns"
          @cancel="showcate_id = false"
          @confirm="confirm_cate_id"
        />
      </van-popup>
    </div>
  </div>
</template>

<script>
import md5 from "md5";
export default {
  name: "",
  data() {
    return {
      show: false,
      formObj: {
        type: 1,
      },
      idstr: "",
      showcate_id: false,
      cate_idlistcolumns: [],
      cate_idlist: [],
      fileList1: [],
      fileList2: [],
      product_imgList: [],
      product: {},
      productlist: [],
      curstep: 1,
      isFirst: true,
      isSubmit: false,
    };
  },
  mounted() {
    this.categoryList();
  },
  methods: {
    changetype() {
      this.formObj.cate = "";
      this.categoryList();
    },
    getidstr() {
      let timestamp = new Date().getTime();
      let idstr = md5(timestamp);
      this.idstr = idstr;
      localStorage.setItem("idstr", this.idstr);
    },
    handleProductItem(item, index) {
      console.log("xxx", item);

      this.isEdit = index;
      this.product = item;
      this.$set(this.product, "imgList", item.product_imgList);

      if (item.product_imgList) {
        this.product_imgList = item.product_imgList.map((ele) => {
          return {
            url: ele,
          };
        });
      } else {
        this.product_imgList = [];
      }
      this.show = true;
    },
    delItem(item, index) {
      this.productlist.splice(index, 1);
    },
    handleSubmit() {
      if (!this.product.title || !this.product.img) {
        this.$toast("请填写项目信息");
        return;
      }
      if (this.isEdit > -1) {
        if (this.productlist[this.isEdit]) {
          console.log("this.productlist", this.productlist);
          console.log("this.isEdit", this.isEdit);
          this.productlist[this.isEdit].product_imgList = this.product.imgList;
        }
      } else {
        this.productlist.push({
          title: this.product.title,
          img: this.product.img,
          content: this.product.content,
          product_imgList: this.product.imgList,
        });
      }
      this.isEdit = -1;
      this.show = false;
      this.product = {};
      this.product_imgList = [];
    },
    nextstep() {
      if (this.curstep == 2) {
        if (this.$route.query.isDetail == 1) {
          this.shopedit();
        } else {
          this.shopadd();
        }
        // window.scrollTo(0, 0);
      } else {
        if (this.isOk()) {
          this.curstep = this.curstep + 1;
          window.scrollTo(0, 0);
        }
      }
    },
    isOk() {
      let isOk = true;
      // return true // 伪代码
      let formObj = this.formObj;
      var phoneReg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      if (!formObj.logo) {
        this.$toast("店铺LOGO" + "不能为空");
        return false;
      }
      if (!formObj.title) {
        this.$toast("门店名称" + "不能为空");
        return false;
      }
      if (!formObj.cate_id) {
        this.$toast("店铺类型" + "不能为空");
        return false;
      }
      if (!formObj.address) {
        this.$toast("店铺地址" + "不能为空");
        return false;
      }
      if (!formObj.phone) {
        this.$toast("联系电话" + "不能为空");
        return false;
      }
      if (!phoneReg.test(formObj.phone)) {
        this.$toast("请填写正确的联系电话");
        return;
      }
      if (!formObj.type) {
        this.$toast("是否上门服务" + "不能为空");
        return false;
      }
      if (!formObj.name) {
        this.$toast("您的姓名" + "不能为空");
        return false;
      }
      if (!formObj.id_card) {
        this.$toast("身份证号" + "不能为空");
        return false;
      }
      let idCardReg =
        /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{7}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$)/;
      if (!idCardReg.test(formObj.id_card)) {
        this.$toast("请填写正确的身份证号");
        return false;
      }
      if (!formObj.mobile1) {
        this.$toast("联系电话1" + "不能为空");
        return false;
      }
      if (!formObj.mobile2) {
        this.$toast("联系电话2" + "不能为空");
        return false;
      }
      if (!phoneReg.test(formObj.mobile1)) {
        this.$toast("请填写正确的联系电话1");
        return;
      }
      if (!phoneReg.test(formObj.mobile2)) {
        this.$toast("请填写正确的联系电话2");
        return;
      }
      if (!formObj.image) {
        this.$toast("店铺展示照片" + "不能为空");
        return false;
      }
      if (!formObj.license) {
        this.$toast("营业执照" + "不能为空");
        return false;
      }
      if (!formObj.describe) {
        this.$toast("店铺简介" + "不能为空");
        return false;
      }
      return isOk;
    },
    async shopedit() {
      try {
        if (this.isSubmit) {
          return;
        }
        this.isSubmit = true;
        let productlist = this.productlist.filter((item) => {
          return item.title && item.img;
        });
        let list = productlist.map((item) => {
          if (item.id) {
            return {
              id: item.id,
              title: item.title,
              img: item.img,
              content: item.content,
            };
          } else {
            return {
              title: item.title,
              img: item.img,
              content: item.content,
            };
          }
        });
        let params = {
          id: this.$route.query.id,
          idstr: this.idstr,
          logo: this.formObj.logo,
          cate_id: this.formObj.cate_id,
          type: this.formObj.type,
          title: this.formObj.title,
          describe: this.formObj.describe,
          address: this.formObj.address,
          phone: this.formObj.phone,
          longitude: 0,
          latitude: 0,
          license: this.formObj.license,
          image: this.formObj.image,
          name: this.formObj.name,
          id_card: this.formObj.id_card,
          mobile1: this.formObj.mobile1,
          mobile2: this.formObj.mobile2,
          list,
        };
        const res = await this.$axios({
          url: "https://ms.nimei.net.cn/mapi/h5.shop/edit",
          data: params,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (res.data.code != 1) {
          this.$toast(res.data.msg);
          return;
        }
        this.$toast.success("提交成功");
        setTimeout(() => {
          this.$router.push({
            name: "page9",
          });
        }, 180);
      } catch (error) {
      } finally {
        setTimeout(() => {
          this.isSubmit = false;
        }, 200);
      }
    },
    async shopdetail() {
      const res = await this.$axios({
        url: "https://ms.nimei.net.cn/mapi/h5.shop/detail",
        data: {
          id: this.$route.query.id,
        },
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let query = res.data.data;
      console.log("query", query);
      this.idstr = query.idstr;
      let obj = this.cate_idlist.find((item) => {
        return item.id == query.cate_id;
      });

      // list
      this.productlist = query.list.map((item) => {
        return {
          id: item.id,
          title: item.title,
          img: item.img,
          content: item.content,
          product_imgList: item.img_url ? [item.img_url] : [],
        };
      });
      console.log("this.productlist", this.productlist);

      let cate = obj?.title || "";
      // this.fileList1 = query.image_url.map((item) => {
      //   return {
      //     url: item,
      //   };
      // });
      for (const key in query.image_url) {
        this.fileList1.push({
          url: query.image_url[key],
        });
      }
      this.fileList2.push({
        url: query.license_url,
      });
      query.logoList = [query.logo_url];
      // this.fileList2 = query.licenseList.map((item) => {
      //   return {
      //     url: item,
      //   };
      // });
      this.formObj = {
        ...query,
        cate,
      };
      console.log("this.formObj", this.formObj);
    },
    confirm_cate_id(value, index) {
      if (value) {
        this.$set(this.formObj, "cate", this.cate_idlist[index].title);
        this.$set(this.formObj, "cate_id", this.cate_idlist[index].id);
        this.showcate_id = false;
        console.log("this.formObj", this.formObj);
      }
    },
    async shopadd() {
      try {
        if (this.isSubmit) {
          return;
        }
        this.isSubmit = true;
        let productlist = this.productlist.filter((item) => {
          return item.title && item.img;
        });
        let list = productlist.map((item) => {
          return {
            title: item.title,
            img: item.img,
            content: item.content,
          };
        });
        let params = {
          id: this.$route.query.id,
          idstr: this.idstr,
          logo: this.formObj.logo,
          cate_id: this.formObj.cate_id,
          type: this.formObj.type,
          title: this.formObj.title,
          describe: this.formObj.describe,
          address: this.formObj.address,
          phone: this.formObj.phone,
          longitude: 0,
          latitude: 0,
          license: this.formObj.license,
          image: this.formObj.image,
          name: this.formObj.name,
          id_card: this.formObj.id_card,
          mobile1: this.formObj.mobile1,
          mobile2: this.formObj.mobile2,
          list,
        };
        const res = await this.$axios({
          url: "https://ms.nimei.net.cn/mapi/h5.shop/add",
          data: params,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (res.data.code != 1) {
          this.$toast(res.data.msg);
          return;
        }
        this.$toast.success("提交成功");
        setTimeout(() => {
          this.$router.push({
            name: "page8",
          });
        }, 180);
      } catch (error) {
      } finally {
        setTimeout(() => {
          this.isSubmit = false;
        }, 200);
      }
    },
    async categoryList() {
      const res = await this.$axios({
        url: "https://ms.nimei.net.cn/mapi/h5.shop/category",
        data: {
          type: this.formObj.type,
        },
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res.data.code != 1) {
        this.$toast(res.data.msg);
        return;
      }

      let list = res.data.data || [];
      this.cate_idlist = list;
      this.cate_idlistcolumns = list.map((item) => {
        return item.title;
      });
      if (!this.isFirst) {
        return;
      }
      if (this.$route.query.isDetail == 1) {
        this.shopdetail();
        return;
      }
      let idstr = localStorage.getItem("idstr") || "";
      if (!idstr) {
        this.getidstr();
      } else {
        this.idstr = idstr;
      }
      this.isFirst = false;
      // let query = this.$route.query.query;
      // if (query) {
      //   query = JSON.parse(query);
      //   let obj = this.cate_idlist.find((item) => {
      //     return item.id == query.cate_id;
      //   });
      //   let cate = obj?.title || "";
      //   this.fileList1 = query.imageList.map((item) => {
      //     return {
      //       url: item,
      //     };
      //   });
      //   this.fileList2 = query.licenseList.map((item) => {
      //     return {
      //       url: item,
      //     };
      //   });
      //   this.formObj = {
      //     ...query,
      //     cate,
      //   };
      //   this.productlist = query.productlist || [];
      //   console.log("this.formObj", this.formObj);
      // }
    },
    async upload_logo(fileObj) {
      let formData = new FormData();
      formData.append("file", fileObj.file); // 文件对象
      formData.append("dir", "images"); // 文件对象
      const res = await this.$axios({
        url: "https://ms.nimei.net.cn/mapi/upload/index",
        data: formData,
        method: "post",
      });
      if (res.data.code != 1) {
        this.$toast(res.data.msg);
        return;
      }
      console.log("xxx", res);
      let { id, path } = res.data;
      this.$set(this.formObj, "logo", id);
      this.$set(this.formObj, "logoList", [path]);
      console.log("this.formObj", this.formObj);
    },
    async upload_image(fileObj) {
      let formData = new FormData();
      formData.append("file", fileObj.file); // 文件对象
      formData.append("dir", "images"); // 文件对象
      const res = await this.$axios({
        url: "https://ms.nimei.net.cn/mapi/upload/index",
        data: formData,
        method: "post",
      });
      if (res.data.code != 1) {
        this.$toast(res.data.msg);
        return;
      }
      let { id, path } = res.data;
      this.$set(this.formObj, "image", id);
      if (this.formObj.imageList && this.formObj.imageList.length > 0) {
        this.$set(this.formObj, "imageList", [...this.formObj.imageList, path]);
      } else {
        this.$set(this.formObj, "imageList", [path]);
      }
      console.log("this.formObj", this.formObj);
    },
    async upload_license(fileObj) {
      let formData = new FormData();
      formData.append("file", fileObj.file); // 文件对象
      formData.append("dir", "images"); // 文件对象
      const res = await this.$axios({
        url: "https://ms.nimei.net.cn/mapi/upload/index",
        data: formData,
        method: "post",
      });
      if (res.data.code != 1) {
        this.$toast(res.data.msg);
        return;
      }
      let { id, path } = res.data;
      this.$set(this.formObj, "license", id);
      if (this.formObj.licenseList && this.formObj.licenseList.length > 0) {
        this.$set(this.formObj, "licenseList", [
          ...this.formObj.licenseList,
          path,
        ]);
      } else {
        this.$set(this.formObj, "licenseList", [path]);
      }
      console.log("this.formObj", this.formObj);
    },
    delete_product_img(value) {
      console.log("value", value);
      this.product_imgList = [];
      this.$set(this.product, "imgList", []);
      this.$set(this.product, "img", "");
    },
    delete_image(value) {
      console.log("value", value);
    },
    delete_license(value) {
      console.log("value", value);
    },
    async upload_product_img(fileObj) {
      let formData = new FormData();
      formData.append("file", fileObj.file); // 文件对象
      formData.append("dir", "images"); // 文件对象
      const res = await this.$axios({
        url: "https://ms.nimei.net.cn/mapi/upload/index",
        data: formData,
        method: "post",
      });
      if (res.data.code != 1) {
        this.$toast(res.data.msg);
        return;
      }
      let { id, path } = res.data;
      this.$set(this.product, "img", id);
      if (this.product.imgList && this.product.imgList.length > 0) {
        this.$set(this.product, "imgList", [...this.product.imgList, path]);
      } else {
        this.$set(this.product, "imgList", [path]);
      }
      console.log("this.product", this.product);
    },
  },
};
</script>

<style scoped lang="less">
img {
  object-fit: cover;
  object-position: center;
}
.step_wrapper {
  margin: 0 auto;
  margin-top: 19px;
  .bz_img {
    width: 251px;
    height: 52px;
  }
}
.item_list {
  display: flex;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid #efefef;

  .info {
    height: 70px;
  }
  .del {
    margin-left: auto;
    width: 18px;
    height: 18px;
    margin-right: 20px;
  }
  .left {
    width: 106px;
    height: 70px;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    margin-right: 12px;
    // border: 1px solid #efefef;
  }
}
.container {
  // padding-bottom: 90px;
}
.item_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}
.label_name {
  margin-bottom: 6px;
}
.file_img {
  width: 80px;
  height: 80px;
  // margin-top: 6px;
}
.logo {
  width: 24px;
  height: 24px;
  object-fit: contain;
}
.banner {
  height: 160px;
  width: 100%;
}
.addBtn {
  margin: 0 auto;
  margin-top: 36px;
  // margin-bottom: 20px;
  width: 125px;
  height: 44px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid #d21315;
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #d21315;
  display: flex;
  justify-content: center;
  align-items: center;
}
.upload_wrapper {
  margin-top: 10px;
}
.upload {
  width: 96px;
  height: 96px;
}
.btn {
  width: 345px;
  height: 44px;
  background: #d21315;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.btn_wrapper {
  margin-top: 30px;
  // position: fixed;
  // left: 0;
  // bottom: 0;
  width: 100%;
  height: 90px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  .desc {
    width: 108px;
    font-size: 12px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #a7632f;
    padding-top: 7px;
    padding-bottom: 9px;
    margin: 0 auto;
  }
  .btn {
    width: 345px;
    height: 44px;
    background: #d21315;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
}
.main {
  margin: 0 auto;
  margin-top: -20px;
  text-align: left;
  background-color: #fff;
  width: 347px;
  // width: 100%;
  border-radius: 10px 10px 0px 0px;
  opacity: 1;
  position: relative;
  // padding-bottom: 16px;
  z-index: 66;
  .title {
    height: 50px;
    font-size: 16px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #333333;
    padding: 14px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #efefef;
    .icon {
      margin-right: 7px;
      width: 22px;
      height: 23px;
    }
  }
  .content {
    // display: flex;
    // align-items: center;
    padding: 0 12px;
    // margin-top: 36px;
    // padding-bottom: 30px;
    .info {
    }
    .left {
      width: 106px;
      height: 70px;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      margin-right: 12px;
    }
  }
}
</style>
