<template>
  <div>
    <div class="wrapper">
      <div class="main">
        <img
          @click="jumpOne"
          src="../assets/xxdj_btn1@2x.png"
          alt=""
          class="left"
        />
        <img
          @click="junpTwo"
          src="../assets/xxdj_btn2@2x.png"
          alt=""
          class="right"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  methods: {
    jumpOne() {
      this.$router.push({
        name: "page7",
      });
    },
    junpTwo() {
      this.$router.push({
        name: "page2",
      });
    },
  },
};
</script>

<style scoped lang="less">
.wrapper {
  background-image: url("../assets/xxdj_xzdjxx_bg@2x.png");
  background-size: 100% auto;
  background-position: top center;
  background-repeat: no-repeat;
  min-height: 100vh;
  position: relative;
  .main {
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    .left {
      width: 106px;
      height: 106px;
      margin-right: 69px;
    }
    .right {
      width: 106px;
      height: 106px;
    }
  }
}
</style>
