<template>
  <div class="container">
    <div class="title">选择住户身份</div>
    <div class="list">
      <div class="item" @click="handlePerson('房主')">
        <img class="item_img" src="../assets/xzsh_img03@2x.png" alt="" />
        <div class="name">房主</div>
        <img class="radio_img" v-if="type == '房主'" src="../assets/radio_s.png" alt="" />
        <img class="radio_img" v-else src="../assets/radio_n.png" alt="" />
      </div>
      <div class="item" @click="handlePerson('非房主')">
        <img class="item_img" src="../assets/xzsh_img04@2x.png" alt="" />
        <div class="name">非房主</div>
        <img class="radio_img" v-if="type == '非房主'" src="../assets/radio_s.png" alt="" />
        <img class="radio_img" v-else src="../assets/radio_n.png" alt="" />
      </div>
      <div class="item" @click="handlePerson('借住')">
        <img class="item_img" src="../assets/xzsh_img02@2x.png" alt="" />
        <div class="name">借住</div>
        <img class="radio_img" v-if="type == '借住'" src="../assets/radio_s.png" alt="" />
        <img class="radio_img" v-else src="../assets/radio_n.png" alt="" />
      </div>
      <div class="item" @click="handlePerson('租户')">
        <img class="item_img" src="../assets/xzsh_img01@2x.png" alt="" />
        <div class="name">租户</div>
        <img class="radio_img" v-if="type == '租户'" src="../assets/radio_s.png" alt="" />
        <img class="radio_img" v-else src="../assets/radio_n.png" alt="" />
      </div>
    </div>
    <div class="desc">*不同身份对应信息不同，请慎重选择身份</div>
    <div class="btn" @click="submit">下一步</div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      type: 0,
    };
  },

  methods: {
    getidstr() {
      let timestamp = new Date().getTime();
      let idstr = md5(timestamp);
      localStorage.setItem("idstr", idstr);
    },
    handlePerson(type) {
      this.type = type;
      console.log('this.type', this.type);
      
    },
    submit() {
      if (this.type == 0) {
        this.$toast("请选择住户身份");
        // this.$toast.loading({
        //   message: "加载中...",
        //   forbidClick: true,
        // });
        return;
      }
      this.$router.push({
        name: "page3",
        query: {
          person_house_type: this.type,
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.title {
  width: 120px;
  height: 28px;
  font-size: 20px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #4d3115;
  line-height: 23px;
  margin: 0 auto;
  padding: 21px 0;
}
.list {
  padding: 0 14px;
  margin-top: 21px;
}
.desc {
  margin-top: 36px;
  width: 223px;
  height: 17px;
  font-size: 12px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #a7632f;
  line-height: 14px;
  margin: 0 auto;
  margin-top: 36px;
}
.btn {
  margin: 0 auto;
  margin-top: 11px;
  width: 345px;
  height: 44px;
  background: #d21315;
  border-radius: 8px 8px 8px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
  margin-bottom: 12px;
  padding: 9px 30px;
  .item_img {
    width: 68px;
    height: 68px;
    margin-right: 12px;
  }
  .radio_img {
    width: 15px;
    height: 15px;
    margin-left: auto;
  }
}
</style>
