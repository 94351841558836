<template>
  <div class="container">
    <div class="wrapper">
      <img src="../assets/djxx_img2@2x.png" alt="" class="banner" />
      <NoList v-if="list.length == 0"></NoList>
      <div v-else class="list">
        <div
          @click="handleitem(item)"
          class="item"
          v-for="item in list"
          :key="item.id"
        >
          <img :src="item.logo" alt="" class="left" />
          <div class="info">
            <div class="one">{{ item.title }}</div>
            <div class="two">
              {{ item.describe }}
            </div>
            <!-- <div class="three" v-if="item.type == 1">房主</div>
            <div class="three" v-if="item.type == 2">非房主</div>
            <div class="three" v-if="item.type == 3">租户</div> -->
          </div>
          <img
            v-if="curId == item.id"
            class="radio_img"
            src="../assets/radio_s.png"
            alt=""
          />
          <img v-else class="radio_img" src="../assets/radio_n.png" alt="" />
        </div>
      </div>
    </div>
    <div class="btn_wrapper">
      <div class="btn" @click="submit">确定</div>
    </div>
  </div>
</template>

<script>
import NoList from "@/components/NoList";
export default {
  name: "",
  components: {
    NoList
  },
  data() {
    return {
      list: [],
      curId: 0,
      curObj: {},
    };
  },
  mounted() {
    this.shopList();
  },
  methods: {
    submit() {
      if (!this.curObj.id) {
        this.$toast('请选择')
        return
      }
      this.$router.push({
        name: "page7",
        query: {
          id: this.curObj.id,
          isDetail: 1,
        },
      });
    },
    handleitem(item) {
      this.curId = item.id;
      this.curObj = item;
    },
    async shopList() {
      const res = await this.$axios({
        url: "https://ms.nimei.net.cn/mapi/h5.shop/index",
        data: {
          idstr: localStorage.getItem("idstr") || "",
          limit: 99999,
          page: 1,
        },
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
      });
            if (res.data.code != 1) {
        this.$toast(res.data.msg);
        return;
      }
      console.log('res', res);
      
      this.list = res.data.data.data || [];
      console.log('this.list', this.list);
    },
  },
};
</script>

<style scoped lang="less">
img {
  object-fit: cover;
  object-position: center;
}
.container {
  padding-bottom: 100px;
}
.btn_wrapper {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 90px;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  padding-top: 20px;
  box-sizing: border-box;
  background-color: #fff;
  overflow: hidden;
  .desc {
    width: 108px;
    font-size: 12px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #a7632f;
    padding-top: 7px;
    padding-bottom: 9px;
    margin: 0 auto;
  }
  .btn {
    width: 345px;
    height: 44px;
    background: #d21315;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
}
.banner {
  height: 123px;
  width: 100%;
}
.list {
  padding: 11px 14px;
  .item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #fff;
    margin-bottom: 12px;
    padding: 9px 30px;
    .info {
      height: 68px;
      display: flex;
      flex-direction: column;
      text-align: left;
      justify-content: flex-start;
      .one {
        height: 21px;
        font-size: 15px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
      }
      .two {
        height: 17px;
        font-size: 12px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #9a9a9a;
        margin-top: 4px;
      }
      .three {
        height: 17px;
        font-size: 12px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        margin-top: 4px;
        color: #6d6d6d;
      }
    }
    .left {
      width: 68px;
      height: 68px;
      margin-right: 12px;
    }
    .radio_img {
      width: 15px;
      height: 15px;
      margin-left: auto;
    }
  }
}
</style>
