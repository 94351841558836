<template>
  <div
    class="container"
    v-if="$route.query.isDetail == 1 || $route.query.person_house_type"
  >
    <img src="../assets/sh_banner@2x.png" alt="" class="banner" />
    <div class="title">填写住户资料</div>
    <div class="step_wrapper">
      <img
        class="bz_img"
        v-if="curstep == 1"
        src="../assets/bz_img01.png"
        alt=""
      />
      <img
        class="bz_img"
        v-if="curstep == 2"
        src="../assets/bz_img02.png"
        alt=""
      />
      <img
        class="bz_img"
        v-if="curstep == 3"
        src="../assets/bz_img03.png"
        alt=""
      />
      <img
        class="bz_img"
        v-if="curstep == 4"
        src="../assets/bz_img04.png"
        alt=""
      />
    </div>
    <div class="form_wrapper">
      <div v-if="curstep == 1" class="form_list">
        <div class="form_item">
          <van-cell-group>
            <van-cell
              @click="showcommunity = true"
              title="小区名称*"
              :value="formObj.community || '选择'"
              is-link
            />
          </van-cell-group>
        </div>
        <div class="form_item">
          <van-cell-group>
            <van-cell
              @click="showlouyu = true"
              title="楼号*"
              is-link
              :value="formObj.building || '选择'"
            />
          </van-cell-group>
        </div>
        <div class="form_item">
          <van-cell-group>
            <van-cell
              @click="showunit = true"
              title="单元号*"
              is-link
              :value="formObj.unit || '选择'"
            />
          </van-cell-group>
        </div>
        <div class="form_item">
          <van-cell-group>
            <van-cell
              @click="showhouse = true"
              title="门牌号*"
              is-link
              :value="formObj.house_number || '选择'"
            />
          </van-cell-group>
        </div>
        <div class="form_item">
          <van-cell-group>
            <van-cell title="所属社区*" :value="formObj.shequ_name" />
          </van-cell-group>
        </div>
        <div class="form_item">
          <div class="item_box">
            <div class="name">是否为常住小区*</div>
            <van-radio-group v-model="formObj.is_home" direction="horizontal">
              <van-radio checked-color="#ee0a24" name="是">是</van-radio>
              <van-radio checked-color="#ee0a24" name="否">否</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="form_item">
          <van-cell-group>
            <van-cell
              @click="show_house_type_list = true"
              title="房产属性*"
              :value="formObj.house_type || '选择'"
              is-link
            />
          </van-cell-group>
        </div>
        <div class="form_item">
          <van-field v-model="formObj.size" label="房屋面积*" />
        </div>
        <div class="form_item">
          <van-field v-model="formObj.sn" label="房屋编号" />
        </div>
        <div class="form_item">
          <div class="item_box">
            <div class="name">是否为电代煤</div>
            <van-radio-group v-model="formObj.is_power" direction="horizontal">
              <van-radio checked-color="#ee0a24" name="是">是</van-radio>
              <van-radio checked-color="#ee0a24" name="否">否</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="form_item">
          <div class="item_box">
            <div class="name">是否为气代煤</div>
            <van-radio-group v-model="formObj.is_gas" direction="horizontal">
              <van-radio checked-color="#ee0a24" name="是">是</van-radio>
              <van-radio checked-color="#ee0a24" name="否">否</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="form_item">
          <div class="textarea_box">
            <div class="label_name">
              填报人一户多房情况请在此填写城区其他房产住址
            </div>
            <van-field
              v-model="formObj.other_address"
              rows="2"
              autosize
              type="textarea"
              maxlength="50"
              placeholder="房产住址"
              show-word-limit
            />
          </div>
        </div>
      </div>
      <div v-if="curstep == 2" class="form_list">
        <div class="form_item">
          <van-field v-model="formObj.real_name" label="姓名*" />
        </div>
        <div class="form_item">
          <van-field v-model="formObj.id_card" label="身份证号*" />
        </div>
        <div class="form_item">
          <div class="item_box">
            <div class="name">性别*</div>
            <van-radio-group v-model="formObj.sex" direction="horizontal">
              <van-radio checked-color="#ee0a24" name="男">男</van-radio>
              <van-radio checked-color="#ee0a24" name="女">女</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="form_item">
          <van-field v-model="formObj.age" label="年龄*" />
        </div>
        <div class="form_item">
          <van-field v-model="formObj.address" label="户籍所在地*" />
          <!-- <van-cell-group>
            <van-cell title="户籍所在地" value="选择" is-link />
          </van-cell-group> -->
        </div>
        <div class="form_item">
          <div class="item_box">
            <div class="name">户籍*</div>
            <van-radio-group v-model="formObj.residence" direction="horizontal">
              <van-radio checked-color="#ee0a24" name="农">农</van-radio>
              <van-radio checked-color="#ee0a24" name="非农">非农</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="form_item">
          <div class="item_box">
            <div class="name">是否白沟户籍*</div>
            <van-radio-group
              v-model="formObj.gaobeidian"
              direction="horizontal"
            >
              <van-radio checked-color="#ee0a24" name="是">是</van-radio>
              <van-radio checked-color="#ee0a24" name="否">否</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="form_item">
          <!-- <van-field v-model="formObj.nation" label="民族*" /> -->
          <van-cell-group>
            <van-cell
              @click="show_nation = true"
              title="民族*"
              :value="formObj.nation || '选择'"
              is-link
            />
          </van-cell-group>
        </div>
        <div class="form_item">
          <!-- <van-field v-model="formObj.politics" label="政治面貌*" /> -->
          <van-cell-group>
            <van-cell
              @click="show_politics = true"
              title="政治面貌*"
              :value="formObj.politics || '选择'"
              is-link
            />
          </van-cell-group>
        </div>
        <div class="form_item">
          <van-field v-model="formObj.education" label="最高学历*" />
        </div>
        <div class="form_item">
          <div class="textarea_box">
            <div class="label_name">
              工作单位及职务(离退, 待业, 自由职业在此处注明)*
            </div>
            <van-field
              v-model="formObj.job"
              rows="2"
              autosize
              type="textarea"
              maxlength="50"
              placeholder="工作单位及职务"
              show-word-limit
            />
            <!-- <div class="label_textarea">
              <input v-model="formObj.job" class="textarea" type="text" />
            </div> -->
          </div>
        </div>
        <div class="form_item">
          <div class="item_box">
            <div class="name">身体状况*</div>
            <van-radio-group v-model="formObj.health" direction="horizontal">
              <van-radio checked-color="#ee0a24" name="健康">健康</van-radio>
              <van-radio checked-color="#ee0a24" name="残疾 （有证）"
                >残疾 （有证）</van-radio
              >
              <van-radio
                checked-color="#ee0a24"
                style="margin-top: 10px"
                name="精神疾病"
                >精神疾病</van-radio
              >
            </van-radio-group>
          </div>
        </div>
        <div class="form_item">
          <div class="item_box">
            <div class="name">婚姻状况*</div>
            <van-radio-group v-model="formObj.marriage" direction="horizontal">
              <van-radio checked-color="#ee0a24" name="未婚">未婚</van-radio>
              <van-radio checked-color="#ee0a24" name="已婚">已婚</van-radio>
              <van-radio checked-color="#ee0a24" name="离婚">离婚</van-radio>
              <van-radio
                checked-color="#ee0a24"
                style="margin-top: 10px"
                name="丧偶"
                >丧偶</van-radio
              >
            </van-radio-group>
          </div>
        </div>
        <div class="form_item">
          <div class="item_box">
            <div class="name">生育状况*</div>
            <van-radio-group v-model="formObj.birth" direction="horizontal">
              <van-radio checked-color="#ee0a24" name="未孕">未孕</van-radio>
              <van-radio checked-color="#ee0a24" name="一胎">一胎</van-radio>
              <van-radio checked-color="#ee0a24" name="二胎">二胎</van-radio>
              <van-radio
                checked-color="#ee0a24"
                style="margin-top: 10px"
                name="三胎及以上"
                >三胎及以上</van-radio
              >
            </van-radio-group>
          </div>
        </div>
      </div>
      <div v-if="curstep == 3" class="form_list">
        <div class="form_item">
          <div class="item_checkbox">
            <div class="name">是否接种*</div>
            <van-radio-group
              @change="handle_covid_19_vaccine"
              v-model="formObj.covid_19_vaccine"
              direction="horizontal"
            >
              <van-radio checked-color="#ee0a24" name="是">已接种</van-radio>
              <van-radio checked-color="#ee0a24" name="否">未接种</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="form_item" v-if="formObj.covid_19_vaccine == '否'">
          <div class="textarea_box">
            <div class="label_name">未接种原因*</div>
            <van-field
              v-model="formObj.covid_19_vaccine_remark"
              rows="2"
              autosize
              type="textarea"
              maxlength="50"
              placeholder="房产住址"
              show-word-limit
            />
          </div>
        </div>
        <div v-if="formObj.covid_19_vaccine == '是'" class="form_item">
          <div class="item_checkbox">
            <!-- <div class="name">接种情况*</div> -->
            <!-- <van-checkbox-group
              v-model="formObj.covid_19_vaccine_type"
              direction="horizontal"
            >
              <van-checkbox checked-color="#D21315" name="1"
                >一针剂</van-checkbox
              >
              <van-checkbox checked-color="#D21315" name="2"
                >二针剂</van-checkbox
              >
              <van-checkbox checked-color="#D21315" name="3"
                >三针剂</van-checkbox
              >
            </van-checkbox-group> -->
            <van-radio-group
              v-model="formObj.covid_19_vaccine_type"
              direction="horizontal"
            >
              <van-radio checked-color="#D21315" name="1">一针剂</van-radio>
              <van-radio checked-color="#D21315" name="2">二针剂</van-radio>
              <van-radio checked-color="#D21315" name="3">三针剂</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div v-if="formObj.covid_19_vaccine_type" class="form_item">
          <van-cell-group>
            <van-cell
              @click="showdateFn(1)"
              title="第一针日期*"
              :value="dateObj[1]"
              is-link
            />
          </van-cell-group>
        </div>
        <div
          v-if="
            formObj.covid_19_vaccine_type == 2 ||
            formObj.covid_19_vaccine_type == 3
          "
          class="form_item"
        >
          <van-cell-group>
            <van-cell
              @click="showdateFn(2)"
              title="第二针日期"
              :value="dateObj[2]"
              is-link
            />
          </van-cell-group>
        </div>
        <div v-if="formObj.covid_19_vaccine_type == 3" class="form_item">
          <van-cell-group>
            <van-cell
              @click="showdateFn(3)"
              title="第三针日期"
              :value="dateObj[3]"
              is-link
            />
          </van-cell-group>
        </div>
      </div>
      <!-- style="margin-top: 21px" -->
      <div v-if="curstep == 3" class="form_list">
        <div class="form_item">
          <van-field v-model="formObj.religious" label="宗教信仰" />
        </div>
        <div class="form_item">
          <van-cell-group>
            <van-cell
              @click="show_insurance = true"
              title="养老保险*"
              :value="formObj.insurance || '选择'"
              is-link
            />
          </van-cell-group>
        </div>
        <div class="form_item">
          <van-cell
            @click="show_medical_type = true"
            title="医疗保险*"
            :value="formObj.medical_type || '选择'"
            is-link
          />
        </div>
        <div
          v-if="formObj.medical_type && formObj.medical_type != '无'"
          class="form_item"
        >
          <!-- <van-field
            v-model="formObj.medical_address"
            label="医疗保险缴纳地址*"
          /> -->
          <div class="item_box">
            <div class="name">医疗保险缴纳地址*</div>
            <van-radio-group
              v-model="formObj.medical_address"
              direction="horizontal"
            >
              <van-radio checked-color="#D21315" name="本市">本市</van-radio>
              <van-radio checked-color="#D21315" name="外市">外市</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="form_item">
          <div class="item_checkbox">
            <div class="name">社救类家庭基本情况家庭属性</div>
            <van-radio-group v-model="formObj.shejiu" direction="horizontal">
              <van-radio checked-color="#ee0a24" name="军属">军属</van-radio>
              <van-radio checked-color="#ee0a24" name="荣军">荣军</van-radio>
              <van-radio checked-color="#ee0a24" name="三属">三属</van-radio>
              <van-radio checked-color="#ee0a24" name="五保">五保</van-radio>
              <van-radio checked-color="#ee0a24" name="低保">低保</van-radio>
              <van-radio checked-color="#ee0a24" name="重病">重病</van-radio>
            </van-radio-group>
          </div>
        </div>
      </div>
      <div v-if="curstep == 4" class="agree_wrapper">
        <div class="agree_item">
          <div class="name">
            <img
              class="djxx_icon_xqxx"
              src="../assets/djxx_icon_xqxx.png"
              alt=""
            />
            您当前有何揪心事和烦心事需要帮助解决
          </div>
          <div class="content">
            <div class="textarea_box">
              <van-field
                v-model="formObj.remark"
                rows="4"
                autosize
                type="textarea"
                maxlength="200"
                placeholder="请输入您的揪心事或烦心事"
                show-word-limit
              />
            </div>
          </div>
        </div>
        <div class="agree_item">
          <div class="name">
            <img
              class="djxx_icon_xqxx"
              src="../assets/djxx_icon_xqxx.png"
              alt=""
            />
            您对社区工作开展有何意见或建议
          </div>
          <div class="content">
            <div class="textarea_box">
              <van-field
                v-model="formObj.remark1"
                rows="4"
                autosize
                type="textarea"
                maxlength="200"
                placeholder="请输入您的意见或建议"
                show-word-limit
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="desc">*不同身份对应信息不同，请慎重选择身份</div>
    <div
      v-if="$route.query.isDetail != 1 && curstep != 4"
      class="btn"
      @click="nextstep"
    >
      下一步
    </div>
    <div
      v-if="$route.query.isDetail != 1 && curstep == 4"
      class="btn"
      @click="nextstep"
    >
      确认提交
    </div>
    <div
      v-if="$route.query.isDetail == 1 && curstep != 4"
      class="btn"
      @click="nextstep"
    >
      下一步
    </div>
    <div
      v-if="$route.query.isDetail == 1 && curstep == 4"
      class="btn"
      @click="goback"
    >
      返回您的登记信息列表
    </div>
    <van-popup v-model="showcommunity" round position="bottom">
      <van-picker
        show-toolbar
        :columns="communitylistcolumns"
        @cancel="showcommunity = false"
        @confirm="confirm_community"
      />
    </van-popup>
    <van-popup v-model="showlouyu" round position="bottom">
      <van-picker
        show-toolbar
        :columns="louyulistcolumns"
        @cancel="showlouyu = false"
        @confirm="confirm_louyu"
      />
    </van-popup>
    <!-- <van-popup v-model="showshequ" round position="bottom">
      <van-picker
        show-toolbar
        :columns="shequlistcolumns"
        @cancel="showshequ = false"
        @confirm="confirm_shequ"
      />
    </van-popup> -->
    <van-popup v-model="showunit" round position="bottom">
      <van-picker
        show-toolbar
        :columns="unitlistcolumns"
        @cancel="showunit = false"
        @confirm="confirm_unit"
      />
    </van-popup>
    <van-popup v-model="showhouse" round position="bottom">
      <van-picker
        show-toolbar
        :columns="houselistcolumns"
        @cancel="showhouse = false"
        @confirm="confirm_house"
      />
    </van-popup>

    <van-popup v-model="show_house_type_list" round position="bottom">
      <van-picker
        show-toolbar
        :columns="house_type_list"
        @cancel="show_house_type_list = false"
        @confirm="confirm_house_type_list"
      />
    </van-popup>

    <van-popup v-model="show_insurance" round position="bottom">
      <van-picker
        show-toolbar
        :columns="insurance_columns"
        @cancel="show_insurance = false"
        @confirm="confirm_insurance"
      />
    </van-popup>
    <van-popup v-model="show_medical_type" round position="bottom">
      <van-picker
        show-toolbar
        :columns="insurance_columns"
        @cancel="show_medical_type = false"
        @confirm="confirm_medical_type"
      />
    </van-popup>
    <van-popup v-model="show_nation" round position="bottom">
      <van-picker
        show-toolbar
        :columns="nation_column"
        @cancel="show_nation = false"
        @confirm="confirm_nation"
      />
    </van-popup>
    <van-popup v-model="show_politics" round position="bottom">
      <van-picker
        show-toolbar
        :columns="politics_column"
        @cancel="show_politics = false"
        @confirm="confirm_politics"
      />
    </van-popup>
    <van-popup v-model="showdate" round position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        @confirm="datetimeconfirm"
        title="选择年月日"
        :min-date="minDate"
        :max-date="maxDate"
      />
    </van-popup>
  </div>
</template>

<script>
import moment from "moment";
import md5 from "md5";

const nationList = [
  {
    id: "01",
    name: "汉族",
  },
  {
    id: "02",
    name: "蒙古族",
  },
  {
    id: "03",
    name: "回族",
  },
  {
    id: "04",
    name: "藏族",
  },
  {
    id: "05",
    name: "维吾尔族",
  },
  {
    id: "06",
    name: "苗族",
  },
  {
    id: "07",
    name: "彝族",
  },
  {
    id: "08",
    name: "壮族",
  },
  {
    id: "09",
    name: "布依族",
  },
  {
    id: "10",
    name: "朝鲜族",
  },
  {
    id: "11",
    name: "满族",
  },
  {
    id: "12",
    name: "侗族",
  },
  {
    id: "13",
    name: "瑶族",
  },
  {
    id: "14",
    name: "白族",
  },
  {
    id: "15",
    name: "土家族",
  },
  {
    id: "16",
    name: "哈尼族",
  },
  {
    id: "17",
    name: "哈萨克族",
  },
  {
    id: "18",
    name: "傣族",
  },
  {
    id: "19",
    name: "黎族",
  },
  {
    id: "20",
    name: "傈僳族",
  },
  {
    id: "21",
    name: "佤族",
  },
  {
    id: "22",
    name: "畲族",
  },
  {
    id: "23",
    name: "高山族",
  },
  {
    id: "24",
    name: "拉祜族",
  },
  {
    id: "25",
    name: "水族",
  },
  {
    id: "26",
    name: "东乡族",
  },
  {
    id: "27",
    name: "纳西族",
  },
  {
    id: "28",
    name: "景颇族",
  },
  {
    id: "29",
    name: "柯尔克孜族",
  },
  {
    id: "30",
    name: "土族",
  },
  {
    id: "31",
    name: "达斡尔族",
  },
  {
    id: "32",
    name: "仫佬族",
  },
  {
    id: "33",
    name: "羌族",
  },
  {
    id: "34",
    name: "布朗族",
  },
  {
    id: "35",
    name: "撒拉族",
  },
  {
    id: "36",
    name: "毛难族",
  },
  {
    id: "37",
    name: "仡佬族",
  },
  {
    id: "38",
    name: "锡伯族",
  },
  {
    id: "39",
    name: "阿昌族",
  },
  {
    id: "40",
    name: "普米族",
  },
  {
    id: "41",
    name: "塔吉克族",
  },
  {
    id: "42",
    name: "怒族",
  },
  {
    id: "43",
    name: "乌孜别克族",
  },
  {
    id: "44",
    name: "俄罗斯族",
  },
  {
    id: "45",
    name: "鄂温克族",
  },
  {
    id: "46",
    name: "崩龙族",
  },
  {
    id: "47",
    name: "保安族",
  },
  {
    id: "48",
    name: "裕固族",
  },
  {
    id: "49",
    name: "京族",
  },
  {
    id: "50",
    name: "塔塔尔族",
  },
  {
    id: "51",
    name: "独龙族",
  },
  {
    id: "52",
    name: "鄂伦春族",
  },
  {
    id: "53",
    name: "赫哲族",
  },
  {
    id: "54",
    name: "门巴族",
  },
  {
    id: "55",
    name: "珞巴族",
  },
  {
    id: "56",
    name: "基诺族",
  },
];
const politicsList = [
  {
    id: "01",
    name: "中共党员",
  },
  {
    id: "02",
    name: "中共预备党员",
  },
  {
    id: "03",
    name: "共青团员",
  },
  {
    id: "04",
    name: "民革党员",
  },
  {
    id: "05",
    name: "民盟盟员",
  },
  {
    id: "06",
    name: "民建会员",
  },
  {
    id: "07",
    name: "民进会员",
  },
  {
    id: "08",
    name: "农工党党员",
  },
  {
    id: "09",
    name: "致公党党员",
  },
  {
    id: "10",
    name: "九三学社社员",
  },
  {
    id: "11",
    name: "台盟盟员",
  },
  {
    id: "12",
    name: "无党派人士",
  },
  {
    id: "13",
    name: "群众",
  },
];

export default {
  name: "",
  data() {
    return {
      curstep: 1, // 伪代码
      formObj: {},
      communitylist: [],
      communitylistcolumns: [],
      showcommunity: false,

      louyulist: [],
      louyulistcolumns: [],
      showlouyu: false,

      shequlist: [],
      shequlistcolumns: [],
      showshequ: false,

      unitlist: [],
      unitlistcolumns: [],
      showunit: false,

      houselist: [],
      houselistcolumns: [],
      showhouse: false,

      house_type_list: [
        "自购商品房",
        "经济适用房",
        "限价房",
        "公租房",
        "廉租房",
      ],
      show_house_type_list: false,
      show_insurance: false,
      show_medical_type: false,
      insurance_columns: ["职工型保险", "居民型保险", "无"],

      idstr: "",

      dateObj: {
        1: "",
        2: "",
        3: "",
      },
      currentDate: "",
      showdate: false,

      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),

      nation_column: [],
      show_politics: false,
      show_nation: false,
      politics_column: [],

      isSubmit: false,
    };
  },
  mounted() {
    this.nation_column = nationList.map((item) => item.name);
    this.politics_column = politicsList.map((item) => item.name);

    this.usercommunity();
    if (this.$route.query.isDetail == 1) {
      this.userdetail();
      return;
    } else {
      if (!this.$route.query.person_house_type) {
        this.$toast("请选择住户身份");
      }
    }
    let idstr = localStorage.getItem("idstr") || "";
    if (!idstr) {
      this.getidstr();
    } else {
      this.idstr = idstr;
    }
  },
  methods: {
    handle_covid_19_vaccine(value) {
      console.log("xxx", value);
      if (value == "否") {
        this.formObj.covid_19_vaccine_remark = "";
      }
    },
    goback() {
      this.$router.push({
        name: "page4",
      });
    },
    async userdetail() {
      const res = await this.$axios({
        url: "https://ms.nimei.net.cn/mapi/h5.user/detail",
        data: {
          user_id: this.$route.query.user_id,
          house_id: this.$route.query.house_id,
          type: this.$route.query.type,
        },
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res.data.code != 1) {
        this.$toast(res.data.msg);
        return;
      }
      let { house, user, xinguan } = res.data.data;
      let covid_19_vaccine_type = xinguan.map((item) => {
        this.dateObj[item.type] = item.date;
        return item.type;
      });
      this.formObj = {
        ...house,
        ...user,
        ...house.info,
        covid_19_vaccine_type,
        is_home: house.is_home,
      };
      this.usershequ();
      this.userlouyu();
      this.userunit();
      this.userhouse();
    },
    datetimeconfirm(value) {
      let date = moment(value).format("YYYY-MM-DD");
      this.showdate = false;
      if (this.showdate_type == 1) {
        this.dateObj[1] = date;
      } else if (this.showdate_type == 2) {
        this.dateObj[2] = date;
      } else if (this.showdate_type == 3) {
        this.dateObj[3] = date;
      }
    },
    showdateFn(type) {
      this.showdate = true;
      this.showdate_type = type;
    },
    async useradd() {
      try {
        if (this.isSubmit) {
          return;
        }
        this.isSubmit = true;
        let xinguan = [];
        for (const key in this.formObj.covid_19_vaccine_type) {
          xinguan.push({
            type: this.formObj.covid_19_vaccine_type[key],
            date: this.dateObj[this.formObj.covid_19_vaccine_type[key]],
          });
        }
        let params = {
          idstr: this.idstr,
          remark: this.formObj.remark,
          remark1: this.formObj.remark1,
          person: {
            house_type: this.$route.query.person_house_type,
            other_address: this.formObj.other_address,
            // type: this.formObj.type,
            type: this.$route.query.type,
            real_name: this.formObj.real_name,
            id_card: this.formObj.id_card,
            age: this.formObj.age,
            sex: this.formObj.sex,
            address: this.formObj.address,
            residence: this.formObj.residence,
            gaobeidian: this.formObj.gaobeidian,
            nation: this.formObj.nation,
            politics: this.formObj.politics,
            education: this.formObj.education,
            job: this.formObj.job,
            health: this.formObj.health,
            marriage: this.formObj.marriage,
            birth: this.formObj.birth,
            religious: this.formObj.religious,
            insurance: this.formObj.insurance,
            medical_type: this.formObj.medical_type,
            medical_address: this.formObj.medical_address,
            shejiu: this.formObj.shejiu,
            covid_19_vaccine: this.formObj.covid_19_vaccine,
            covid_19_vaccine_remark: this.formObj.covid_19_vaccine_remark,
          },
          house: {
            community_id: this.formObj.community_id,
            building_id: this.formObj.building_id,
            unit_id: this.formObj.unit_id,
            house_id: this.formObj.house_id,
            shequ: this.formObj.shequ,
            is_home: this.formObj.is_home,
            house_type: this.formObj.house_type,
            size: this.formObj.size,
            sn: this.formObj.sn,
            is_power: this.formObj.is_power,
            is_gas: this.formObj.is_gas,
          },
          xinguan: xinguan,
        };
        const res = await this.$axios({
          url: "https://ms.nimei.net.cn/mapi/h5.user/add",
          data: params,
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (res.data.code != 1) {
          this.$toast(res.data.msg);
          return;
        }
        this.$toast.success("成功");
        setTimeout(() => {
          this.$router.push({
            name: "page4",
          });
        }, 180);
      } catch (error) {
      } finally {
        setTimeout(() => {
          this.isSubmit = false;
        }, 200);
      }
    },
    nextstep() {
      if (this.curstep == 4) {
        this.useradd();
        // window.scrollTo(0, 0);
      } else {
        if (this.curstep == 1 && this.isOk1()) {
          this.curstep = this.curstep + 1;
          window.scrollTo(0, 0);
        } else if (this.curstep == 2 && this.isOk2()) {
          this.curstep = this.curstep + 1;
          window.scrollTo(0, 0);
        } else if (this.curstep == 3 && this.isOk3()) {
          this.curstep = this.curstep + 1;
          window.scrollTo(0, 0);
        }
      }
    },
    isOk1() {
      let isOk = true;
      let formObj = this.formObj;
      // return true; // 伪代码
      if (!formObj.community) {
        this.$toast("小区名称" + "不能为空");
        return false;
      }
      if (!formObj.building) {
        this.$toast("楼号" + "不能为空");
        return false;
      }
      if (!formObj.unit) {
        this.$toast("单元号" + "不能为空");
        return false;
      }
      if (!formObj.house_number) {
        this.$toast("门牌号" + "不能为空");
        return false;
      }
      if (!formObj.shequ_name) {
        this.$toast("所属社区" + "不能为空");
        return false;
      }
      if (!formObj.is_home) {
        this.$toast("是否为常住小区" + "不能为空");
        return false;
      }
      if (!formObj.house_type) {
        this.$toast("房产属性" + "不能为空");
        return false;
      }
      if (!formObj.size) {
        this.$toast("房屋面积" + "不能为空");
        return false;
      }
      return isOk;
    },
    isOk3() {
      let isOk = true;
      let formObj = this.formObj;
      let dateObj = this.dateObj;
      // return true; // 伪代码
      if (!formObj.covid_19_vaccine) {
        this.$toast("是否接种" + "不能为空");
        return false;
      }
      console.log("formObj", formObj);

      if (
        formObj.covid_19_vaccine == "否" &&
        !formObj.covid_19_vaccine_remark
      ) {
        this.$toast("未接种原因" + "不能为空");
        return false;
      }
      if (formObj.covid_19_vaccine == "是") {
        if (!formObj.covid_19_vaccine_type) {
          this.$toast("针剂" + "不能为空");
          return false;
        }
        if (formObj.covid_19_vaccine_type == "1" && !dateObj[1]) {
          this.$toast("第一针日期" + "不能为空");
          return false;
        }
        if (formObj.covid_19_vaccine_type == "2" && !dateObj[1]) {
          this.$toast("第一针日期" + "不能为空");
          return false;
        }
        if (formObj.covid_19_vaccine_type == "2" && !dateObj[2]) {
          this.$toast("第二针日期" + "不能为空");
          return false;
        }
        if (formObj.covid_19_vaccine_type == "3" && !dateObj[1]) {
          this.$toast("第一针日期" + "不能为空");
          return false;
        }
        if (formObj.covid_19_vaccine_type == "3" && !dateObj[2]) {
          this.$toast("第二针日期" + "不能为空");
          return false;
        }
        if (formObj.covid_19_vaccine_type == "3" && !dateObj[3]) {
          this.$toast("第三针日期" + "不能为空");
          return false;
        }
      }

      if (!formObj.insurance) {
        this.$toast("养老保险" + "不能为空");
        return false;
      }
      if (!formObj.medical_type) {
        this.$toast("医疗保险" + "不能为空");
        return false;
      }
      if (formObj.medical_type != "无" && !formObj.medical_address) {
        this.$toast("医疗保险缴纳地址" + "不能为空");
        return false;
      }
      return isOk;
    },
    isOk2() {
      let isOk = true;
      // return true; // 伪代码
      // var phoneReg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      let formObj = this.formObj;
      if (!formObj.real_name) {
        this.$toast("姓名" + "不能为空");
        return false;
      }
      if (!formObj.id_card) {
        this.$toast("身份证号" + "不能为空");
        return false;
      }
      let idCardReg =
        /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{7}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$)/;
      if (!idCardReg.test(formObj.id_card)) {
        this.$toast("请填写正确的身份证号");
        return false;
      }
      if (!formObj.sex) {
        this.$toast("性别" + "不能为空");
        return false;
      }
      if (!formObj.age) {
        this.$toast("年龄" + "不能为空");
        return false;
      }
      if (!formObj.address) {
        this.$toast("户籍所在地" + "不能为空");
        return false;
      }
      if (!formObj.residence) {
        this.$toast("户籍" + "不能为空");
        return false;
      }
      if (!formObj.gaobeidian) {
        this.$toast("是否高碑店户籍" + "不能为空");
        return false;
      }
      if (!formObj.nation) {
        this.$toast("民族" + "不能为空");
        return false;
      }
      if (!formObj.politics) {
        this.$toast("政治面貌" + "不能为空");
        return false;
      }
      if (!formObj.education) {
        this.$toast("最高学历" + "不能为空");
        return false;
      }
      if (!formObj.job) {
        this.$toast("工作单位及职务" + "不能为空");
        return false;
      }
      if (!formObj.health) {
        this.$toast("身体状况" + "身体状况");
        return false;
      }
      if (!formObj.marriage) {
        this.$toast("婚姻状况" + "不能为空");
        return false;
      }
      if (!formObj.birth) {
        this.$toast("生育状况" + "不能为空");
        return false;
      }
      return isOk;
    },
    confirm_community(value, index) {
      if (value) {
        this.$set(this.formObj, "community", this.communitylist[index].name);
        this.$set(this.formObj, "community_id", this.communitylist[index].id);
        this.showcommunity = false;
        this.userlouyu();
        this.usershequ();

        this.$set(this.formObj, "building", "");
        this.$set(this.formObj, "building_id", "");
        this.louyulist = [];
        this.louyulistcolumns = [];
        this.unitlistcolumns = [];
        this.houselistcolumns = [];
        this.unitlist = [];
        this.houselist = [];
        this.$set(this.formObj, "unit", "");
        this.$set(this.formObj, "unit_id", "");
        this.$set(this.formObj, "house_number", "");
        this.$set(this.formObj, "house_id", "");
        this.$set(this.formObj, "shequ_name", "");
        this.$set(this.formObj, "shequ", "");
      }
    },
    confirm_louyu(value, index) {
      if (value) {
        this.$set(this.formObj, "building", this.louyulist[index].name);
        this.$set(this.formObj, "building_id", this.louyulist[index].id);
        this.showlouyu = false;
        this.userunit();

        this.unitlistcolumns = [];
        this.houselistcolumns = [];
        this.unitlist = [];
        this.houselist = [];
        this.$set(this.formObj, "unit", "");
        this.$set(this.formObj, "unit_id", "");
        this.$set(this.formObj, "house_number", "");
        this.$set(this.formObj, "house_id", "");
      }
    },
    confirm_unit(value, index) {
      if (value) {
        this.$set(this.formObj, "unit", this.unitlist[index].name);
        this.$set(this.formObj, "unit_id", this.unitlist[index].id);
        this.showunit = false;
        this.userhouse();

        this.houselistcolumns = [];
        this.houselist = [];
        this.$set(this.formObj, "house_number", "");
        this.$set(this.formObj, "house_id", "");
      }
    },
    confirm_house(value, index) {
      if (value) {
        this.$set(
          this.formObj,
          "house_number",
          this.houselist[index].house_number
        );
        this.$set(this.formObj, "house_id", this.houselist[index].id);
        this.showhouse = false;
        console.log("this.formObj", this.formObj);
      }
    },
    confirm_house_type_list(value, index) {
      if (value) {
        this.$set(this.formObj, "house_type", this.house_type_list[index]);
        this.show_house_type_list = false;
      }
    },
    confirm_insurance(value, index) {
      if (value) {
        this.$set(this.formObj, "insurance", this.insurance_columns[index]);
        this.show_insurance = false;
      }
    },
    confirm_medical_type(value, index) {
      if (value) {
        this.$set(this.formObj, "medical_type", this.insurance_columns[index]);
        this.show_medical_type = false;
      }
    },
    confirm_nation(value, index) {
      if (value) {
        this.$set(this.formObj, "nation", this.nation_column[index]);
        this.show_nation = false;
      }
    },
    confirm_politics(value, index) {
      if (value) {
        this.$set(this.formObj, "politics", this.politics_column[index]);
        this.show_politics = false;
      }
    },
    async usercommunity() {
      const res = await this.$axios({
        url: "https://ms.nimei.net.cn/mapi/h5.user/community",
        data: {},
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res.data.code != 1) {
        this.$toast(res.data.msg);
        return;
      }
      let communitylist = res.data.data;
      this.communitylist = communitylist;
      this.communitylistcolumns = communitylist.map((item) => {
        return item.name;
      });
    },
    async userlouyu() {
      const res = await this.$axios({
        url: "https://ms.nimei.net.cn/mapi/h5.user/louyu",
        params: {
          community_id: this.formObj.community_id,
        },
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res.data.code != 1) {
        this.$toast(res.data.msg);
        return;
      }
      let louyulist = res.data.data;
      this.louyulist = louyulist;
      this.louyulistcolumns = louyulist.map((item) => {
        return item.name;
      });
    },
    async usershequ() {
      const res = await this.$axios({
        url: "https://ms.nimei.net.cn/mapi/h5.user/shequ",
        params: {
          community_id: this.formObj.community_id,
        },
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res.data.code != 1) {
        this.$toast(res.data.msg);
        return;
      }
      let { name, id } = res.data.data;
      this.$set(this.formObj, "shequ_name", name);
      this.$set(this.formObj, "shequ", id);
    },

    async userunit() {
      const res = await this.$axios({
        url: "https://ms.nimei.net.cn/mapi/h5.user/unit",
        params: {
          building_id: this.formObj.building_id,
        },
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res.data.code != 1) {
        this.$toast(res.data.msg);
        return;
      }
      let unitlist = res.data.data;
      this.unitlist = unitlist;
      this.unitlistcolumns = unitlist.map((item) => {
        return item.name;
      });
    },
    async userhouse() {
      const res = await this.$axios({
        url: "https://ms.nimei.net.cn/mapi/h5.user/house",
        params: {
          unit_id: this.formObj.unit_id,
        },
        method: "get",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res.data.code != 1) {
        this.$toast(res.data.msg);
        return;
      }
      let houselist = res.data.data;
      this.houselist = houselist;
      this.houselistcolumns = houselist.map((item) => {
        return item.house_number;
      });
    },
    getidstr() {
      let timestamp = new Date().getTime();
      let idstr = md5(timestamp);
      this.idstr = idstr;
      localStorage.setItem("idstr", this.idstr);
    },
  },
};
</script>
<style lang="less">
.banner {
  height: 160px;
  width: 100%;
}
.van-cell {
  padding-left: 0;
  padding-right: 0;
}
.van-cell__title {
  text-align: left;
}
</style>
<style scoped lang="less">
.container {
  padding-bottom: 30px;
}
.agree_wrapper {
  .agree_item {
    background: #ffffff;
    text-align: left;
    margin-bottom: 10px;
    .djxx_icon_xqxx {
      margin-right: 7px;
      width: 22px;
      height: 23px;
    }
    .name {
      height: 50px;
      background: #ffffff;
      border-radius: 10px 10px 0px 0px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #efefef;
      padding: 0 14px;
      font-size: 16px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #333333;
    }
    .content {
      padding: 0 20px;
      // height: 155px;
      width: 100%;
      font-size: 15px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #b4b4b4;
      line-height: 18px;
    }
  }
}
.form_wrapper {
  margin-top: 21px;
  padding: 0 14px;
  .form_list {
    background-color: #fff;
    padding: 0 12px;
  }
}
.title {
  width: 120px;
  height: 28px;
  font-size: 20px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #4d3115;
  line-height: 23px;
  margin: 0 auto;
  padding: 21px 0;
}
.step_wrapper {
  margin: 0 auto;
  margin-top: 19px;
  .bz_img {
    width: 311px;
    height: 52px;
  }
}
.list {
  padding: 0 14px;
  margin-top: 21px;
}
.desc {
  margin-top: 36px;
  width: 223px;
  height: 17px;
  font-size: 12px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #a7632f;
  line-height: 14px;
  margin: 0 auto;
  margin-top: 36px;
}
.btn {
  margin: 0 auto;
  margin-top: 11px;
  width: 345px;
  height: 44px;
  background: #d21315;
  border-radius: 8px 8px 8px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
  margin-bottom: 12px;
  padding: 9px 30px;
  .item_img {
    width: 68px;
    height: 68px;
    margin-right: 12px;
  }
  .radio_img {
    width: 15px;
    height: 15px;
    margin-left: auto;
  }
}
</style>
