<template>
  <div class="container">
    <div class="wrapper">
      <img src="../assets/img_chengg@2x.png" alt="" class="img" />
      <div class="title">“感谢您参与此次调研”</div>
      <div class="btn" @click="goList">查看您的登记信息</div>
      <div class="btn" @click="goCreate">继续添加商户信息</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  methods: {
    goList() {
      this.$router.push({
        name: "page8",
      });
    },
    goCreate() {
      this.$router.push({
        name: "page7",
      });
    },
  },
};
</script>

<style scoped>
.wrapper {
  padding-top: 70px;
}
.img {
  width: 110px;
  height: 110px;
}
.title {
  width: 164px;
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #333333;
  margin: 0 auto;
  margin-top: 21px;
  margin-bottom: 46px;
}
.btn {
  margin: 0 auto;
  width: 345px;
  height: 44px;
  background: #d21315;
  border-radius: 8px 8px 8px 8px;
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 21px;
}
</style>
