<template>
  <div class="no_list-root">
    <img
      class="no_list-img"
      lazy-load="true"
      src="../../static/images/utils/no_mylist.png"
    />
    <!-- <image v-if="type == 'cart'" mode="aspectFill" lazy-load="true" src="/static/images/utils/no_cart.png" />
      <image v-else-if="type == 'list'" mode="aspectFill" lazy-load="true" src="/static/images/utils/no_list.png" />
      <image v-else mode="aspectFill" lazy-load="true" src="/static/images/utils/no_mylist.png" /> -->
    <div v-if="type == 'study'" class="no_list-text">
      当前还没有任何学习记录， 快去挑选你喜欢的课程吧～
    </div>
    <div v-if="type == 'order'" class="no_list-text">当前暂无订单～</div>
    <div v-if="type == 'exam'" class="no_list-text">
      当前暂无在线考试， 课程学习完就可以参加该课程考试咯～
    </div>
    <div v-if="type == 'examRecord'" class="no_list-text">
      当前暂无考试记录～
    </div>
    <div v-if="type == 'grades'" class="no_list-text">
      当前还没有任何成绩记录， 学习完的课程记得参加考试哦～
    </div>
    <div v-if="type == 'cart'" class="no_list-text">
      当前购物车为空， 快去挑选你喜欢的课程吧～
    </div>
    <div v-if="type == 'list'" class="no_list-text">当前列表无内容～</div>
    <div v-if="type == 'detail'" class="no_list-text">当前无内容～</div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    type: {
      type: String,
      default: "list",
    },
  },
  data() {
    return {};
  },
  created() {},
  components: {},
};
</script>

<style scpoed lang="less">
.no_list-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
  .no_list-img {
    // width: 280px;
    height: 150px;
  }
  .no_list-text {
    width: 132px;
    height: 24px;
    font-size: 12px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #999999;
    line-height: 12px;
    margin-top: 14px;
    text-align: center;
  }
}
</style>
