import { render, staticRenderFns } from "./Page3.vue?vue&type=template&id=38cc5b7e&scoped=true&"
import script from "./Page3.vue?vue&type=script&lang=js&"
export * from "./Page3.vue?vue&type=script&lang=js&"
import style0 from "./Page3.vue?vue&type=style&index=0&id=38cc5b7e&prod&lang=less&"
import style1 from "./Page3.vue?vue&type=style&index=1&id=38cc5b7e&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38cc5b7e",
  null
  
)

export default component.exports